<template>
<div>
  <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="trashTableColumns"
      back-to-list-path="settings-taxes-list"
      :table-config-options="getTableConfigOptions()"
  >
    <template #cell(is_active)="{ data }">
      <div class="text-nowrap">
        {{ data.item.is_active ? 'Active': 'Inactive' }}
      </div>
    </template>
    <template #cell(actions)="{ data }">
      <div class="text-nowrap">
        <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Set to active')"
            icon="LLoadIcon"
            size="24"
            class="cursor-pointer mr-1 border-dotted defaultIconColor"
            @click="restoreContact(data.item)"

        />
        <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Delete')"
            icon="LTrashIcon"
            size="24"
            class="cursor-pointer mr-1 border-dotted defaultIconColor"
            @click="remove(data.item)"
        />
      </div>
    </template>
  </l-table>
</div>
</template>
<script>
import LTable from "@/views/components/LTable/LTable.vue";
import tableConfig from "@/views/settings/general/taxes/taxesConfig";
import {VBTooltip} from "bootstrap-vue";

export default {
  name: 'TaxesTrashList',
  components: {LTable},
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    getTableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/getInActiveList`,
      }
    },
    remove(data) {
      this.confirmNotification(this, data.id, `${this.MODULE_NAME}/del`).then(() => {
        this.refetchData()
      })
    },
    restoreContact(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/setActive`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
          .then(() => {
            this.refetchData()
          })
    },
  },
  setup() {
    const MODULE_NAME = 'settings-taxes'
    const { trashTableColumns, fields, ACCESS_ABILITY_TAXES } = tableConfig()
    return {
      trashTableColumns,
      MODULE_NAME,
      fields,
      ACCESS_ABILITY_TAXES,
    }
  },
};
</script>

<style scoped>

</style>